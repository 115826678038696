import KnTextArea from '../../../shared/components/KnTextArea.vue';
import KnTextField from '../../../shared/components/KnTextField.vue';
import {
  emptyGrade,
  emptyMateriaAlumno,
  GradeActions,
} from '../../helpers/gradeOptions';
import { percentFormatMX } from '../../../shared/helpers/dataUtils';
export default {
  name: 'KnAuthorizationGradeDetail',
  components: { KnTextArea, KnTextField },
  mixins: [],
  props: {
    grade: {
      type: Object,
      default: () => emptyGrade,
    },
    gradeDetails: {
      type: Array,
      default: () => [],
    },
    loading: {
      type: Boolean,
      default: false,
    },
    materiaAlumno: {
      type: Object,
      default: () => emptyMateriaAlumno,
    },
  },
  data() {
    return {
      headers: [
        {
          text: 'Criterio de evaluación',
          value: 'criterio_evaluacion.nombre',
          class: 'purple--text',
        },
        {
          text: 'Porcentaje asignado',
          value: 'criterio_evaluacion.porcentaje',
          class: 'purple--text',
          align: 'right',
          sortable: false,
        },
        {
          text: 'Porcentaje obtenido',
          value: 'porcentaje_obtenido',
          class: 'purple--text',
          align: 'right',
          sortable: false,
        },
        {
          text: 'Puntaje total',
          value: 'puntaje_total',
          class: 'purple--text',
          align: 'right',
          sortable: false,
        },
        {
          text: 'Puntaje obtenido',
          value: 'puntaje_obtenido',
          class: 'purple--text',
          align: 'right',
          sortable: false,
        },
      ],
      formatter: percentFormatMX,
    };
  },
  computed: {
    subjectStudentSimple() {
      return {
        alumno: this.materiaAlumno.alumno,
        materia: this.materiaAlumno.materia,
      };
    },
    colorStatus() {
      switch (this.grade.estatus_calificacion.dato) {
        case 'En revisión':
          return 'primary';
        case 'Aprobada':
          return 'success';
        case 'Rechazada':
          return 'error';
        default:
          return 'graydefault';
      }
    },
  },
  created() {},
  methods: {
    calculatePercentageObtained(item) {
      return (
        (item.puntaje_obtenido * item.criterio_evaluacion.porcentaje) /
        item.puntaje_total
      );
    },
    close() {
      this.$emit('onClose');
    },
    emitPause() {
      this.$emit('onPause', {
        ...this.grade,
        ...this.subjectStudentSimple,
        action: GradeActions.PAUSE,
      });
    },
    emitAccept() {
      this.$emit('onAccept', {
        ...this.grade,
        ...this.subjectStudentSimple,
        action: GradeActions.ACCEPT,
      });
    },
    emitReject() {
      this.$emit('onReject', {
        ...this.grade,
        ...this.subjectStudentSimple,
        action: GradeActions.REJECT,
      });
    },
    emitEdit() {
      this.$emit('onEdit', {
        ...this.grade,
        ...this.subjectStudentSimple,
        action: GradeActions.EDIT,
      });
    },
    emitDetail() {
      this.$emit('onDetail', {
        ...this.grade,
        ...this.subjectStudentSimple,
        action: GradeActions.DETAIL,
      });
    },
  },
};
