import { emptyMateriaAlumno, GradeActions } from '../../helpers/gradeOptions';

export default {
  name: 'KnAuthorizationGradeItem',
  components: {},
  mixins: [],
  props: {
    materiaAlumno: {
      type: Object,
      default: () => emptyMateriaAlumno,
    },
    disablePause: {
      type: Boolean,
      default: false,
    },
    disableAccept: {
      type: Boolean,
      default: false,
    },
    disableReject: {
      type: Boolean,
      default: false,
    },
    disableEdit: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      // grade: emptyGrade,
    };
  },
  computed: {
    grade() {
      return this.materiaAlumno.grade;
    },
    subjectStudentSimple() {
      return {
        alumno: this.materiaAlumno.alumno,
        materia: this.materiaAlumno.materia,
      };
    },
    colorStatus() {
      switch (this.grade.estatus_calificacion.dato) {
        case 'En revisión':
          return 'primary';
        case 'Aprobada':
          return 'success';
        case 'Rechazada':
          return 'error';
        default:
          return 'graydefault';
      }
    },
  },
  created() {},
  methods: {
    emitPause() {
      this.$emit('onPause', {
        ...this.grade,
        ...this.subjectStudentSimple,
        action: GradeActions.PAUSE,
      });
    },
    emitAccept() {
      this.$emit('onAccept', {
        ...this.grade,
        ...this.subjectStudentSimple,
        action: GradeActions.ACCEPT,
      });
    },
    emitReject() {
      this.$emit('onReject', {
        ...this.grade,
        ...this.subjectStudentSimple,
        action: GradeActions.REJECT,
      });
    },
    emitEdit() {
      this.$emit('onEdit', {
        ...this.grade,
        ...this.subjectStudentSimple,
        action: GradeActions.EDIT,
      });
    },
    emitDetail() {
      this.$emit('onDetail', {
        ...this.grade,
        ...this.subjectStudentSimple,
        action: GradeActions.DETAIL,
      });
    },
  },
};
